import React from 'react'

import './dashboard.css'
import M from 'materialize-css';
import CustomTable from '../customComponents/customTable/customTable'
import Spinner from '../customComponents/spinner/spinner'
import { cacheHeaders, basePath } from '../../config'

const columns = [
    { Header: "ID", accessor: "account_id", filter: "text" },
    { Header: "Name",accessor: "account_name",filter: "text" },
    { Header: "Domain",accessor: "domain_name",filter: "text" },
    { Header: "Server",accessor: "server_name",filter: "text" },
    { Header: "Email",accessor: "email_type",filter: "text" },
    { Header: "Domain Registrar",accessor: "domain_register",filter: "text" },
    { Header: "DR_Username",accessor: "dr_username",filter: "text" },
    { Header: "DR_Password",accessor: "dr_password",filter: "text" },
    { Header: "Name Server",accessor: "name_server",filter: "text" },
    { Header: "Notes",accessor: "notes",filter: "text" },
    { Header: "Rep. Name",accessor: "rep_name",filter: "text" },
    { Header: "Domain Expiry",accessor: "domain_expiry",filter: "text" },
]

const initialFormData = {
    account_id: 0,
    account_name: '',
    domain_name: '',
    server_name: '',
    email_type: '',
    domain_register: '',
    dr_username: '',
    dr_password: '',
    name_server: '',
    notes: '',
    rep_name: '',
    domain_expiry: ''
}

const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }

class Dashboard extends React.Component{

    state={
        isLoading: true,
        accounts: [],
        formType: 'Add',
        formData: initialFormData,
        formOpen: false,
        currSelectedRow: 0,
        currSelectedName: ''
    }

    toaster = (success,message) => {
        let classList = ['rounded'];
        success ? classList.push('ToastSuccess') : classList.push('ToastFailure');
        M.toast({html: message, classes: classList.join(' ')});
    }

    inputChangeHandler = (ev) => {
        const data = {...this.state.formData};
        data[ev.target.name] = ev.target.value;
        this.setState({formData: data});
    }

    dateSelectHandler = (val) => {
        let dateVal = new Date(val).toLocaleDateString('en-US',dateOptions);
        const data = {...this.state.formData};
        data['domain_expiry'] = dateVal;
        this.setState({formData: data});
    }

    clearFormHandler = () => {
        this.setState({formData: initialFormData, formType: 'Add', currSelectedRow: 0, currSelectedName: ''});
        document.getElementById('account_form').reset();
    }

    formOpenHandler = () => {
        this.setState(prevState => ({formOpen: !prevState.formOpen}));
    }

    rowSelectHandler = (id,name) => {
        if(id !== this.state.currSelectedRow){
            this.setState({currSelectedRow:id,currSelectedName:name});
        }
    }

    closeHandler = () => {
        this.formOpenHandler();
        this.clearFormHandler();
    }

    addHandler = () => {
        this.clearFormHandler();
        this.formOpenHandler();
    }

    editHandler = () => {
        this.clearFormHandler();
        let accounts = [...this.state.accounts];
        let selectedAccount = accounts.find(account => account.account_id === this.state.currSelectedRow);
        this.setState({formOpen: true, formType: 'Edit', formData: selectedAccount});
    }

    formSubmitHandler = (ev) => {
        ev.preventDefault();
        this.setState({isLoading: true});
        let postData = {...this.state.formData};
        fetch(basePath+'/account',{
            headers:{
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.authToken,
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify(postData)
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(data => {
            if(data.success){
                this.closeHandler();
                this.getAccounts();
            }
            this.toaster(data.success,data.message);
            this.setState({isLoading: false});
        })
        .catch(err => {
            this.toaster(false,"An error occurred and the action could not be completed");
            this.setState({isLoading: false});
        })
    }

    deleteAccount = () => {
        this.setState({isLoading: true});
        fetch(basePath+'/account/'+this.state.currSelectedRow,{
            headers:{
                ...cacheHeaders,
                'Authorization': 'Bearer '+ localStorage.authToken,
                'Accept': 'application/json'
            },
            method: 'delete',
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status.toString())))
        .then(data => {
            this.toaster(data.success,data.message);
            this.setState({isLoading: false,currSelectedRow: 0, currSelectedName: ''});
            this.getAccounts();
        })
        .catch(err => {
            this.toaster(false,"An error occurred while deleting the account");
            this.setState({isLoading: false});
        })
    }

    getAccounts = () => {
        fetch(basePath+'/accounts',{
            headers:{
                'Accept': 'application/json',
                'Authorization': 'Bearer '+ localStorage.authToken,
                ...cacheHeaders,
            }
        })
        .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(result => {
            if(result.success){
                this.setState({accounts: result.data, isLoading: false});
                // For auto completing Account Names
                let autocompleteData={};
                result.data.forEach(elem => {autocompleteData[elem.account_name] = null;});
                const elems = document.querySelectorAll('.autocomplete');
                const selectElems = document.querySelectorAll('select');
                M.Autocomplete.init(elems, {data:autocompleteData});
                M.FormSelect.init(selectElems);
                let dateElem = document.querySelectorAll('.datepicker');
                M.Datepicker.init(dateElem, {onSelect: this.dateSelectHandler,autoClose: true});
            }else{
                this.toaster(false, result.message);
                this.setState({isLoading: false});
            }
        })
        .catch(err => {
            this.setState({isLoading: false});
            this.toaster(false, "An error occurred and the accounts cannot be fetched!");
        })
    }

    componentDidMount(){
        M.AutoInit();
        this.getAccounts();
    }

    render(){
        const formData = {...this.state.formData}
        let formClasses = ['FormContainer']
        this.state.formOpen && formClasses.push('FormOpen')
        return(
            <>
            <div className="Dashboard">
                <div className="ActionBar">
                    <button className="btn-floating waves-effect waves-light tooltipped" data-tooltip='Add Account' onClick={this.addHandler}><i className="material-icons">add</i></button>
                    <button className="btn-floating waves-effect waves-light tooltipped" data-tooltip='Edit Account' onClick={this.editHandler} disabled={!this.state.currSelectedRow}><i className="material-icons">edit</i></button>
                    <button className="btn-floating waves-effect waves-light tooltipped modal-trigger" data-target='delete_modal' data-tooltip='Delete Account' disabled={!this.state.currSelectedRow}><i className="material-icons">delete</i></button>
                </div>
                {
                    this.state.accounts.length === 0 ? <h5 style={{marginTop: '100px'}}>Data Not Available!</h5> : 
                    <CustomTable columns={columns} data={this.state.accounts} select={this.rowSelectHandler} pagination filter exportable />
                }
                <div className={formClasses.join(' ')}>
                    <div className='FormHeader'>
                        <span>{this.state.formType+' Account'}</span>
                        <i onClick={this.closeHandler} className="material-icons">close</i>
                    </div>
                    <form onSubmit={this.formSubmitHandler} id="account_form" className="AccountForm">
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="account_name" name='account_name' type="text" value={formData.account_name} onChange={this.inputChangeHandler} required autoComplete='off' className="validate autocomplete" />
                                <label className={formData.account_name !== '' ? 'active' : ''} htmlFor="account_name">Account Name</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="domain_name" name='domain_name' type="text" value={formData.domain_name} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.domain_name !== '' ? 'active' : ''} htmlFor="domain_name">Domain</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="server_name" name='server_name' type="text" value={formData.server_name} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.server_name !== '' ? 'active' : ''} htmlFor="server_name">Server</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="email_type" name='email_type' type="text" value={formData.email_type} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.email_type !== '' ? 'active' : ''} htmlFor="email_type">Email</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="domain_register" name='domain_register' type="text" value={formData.domain_register} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.domain_register !== '' ? 'active' : ''} htmlFor="domain_register">Domain Registrar</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="dr_username" name='dr_username' type="text" value={formData.dr_username} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.dr_username !== '' ? 'active' : ''} htmlFor="dr_username">DR Username</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="dr_password" name='dr_password' type="text" value={formData.dr_password} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.dr_password !== '' ? 'active' : ''} htmlFor="dr_password">DR Password</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="name_server" name='name_server' type="text" value={formData.name_server} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.name_server !== '' ? 'active' : ''} htmlFor="name_server">Name Server</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="notes" name='notes' type="text" value={formData.notes} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.notes !== '' ? 'active' : ''} htmlFor="notes">Notes</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="rep_name" name='rep_name' type="text" value={formData.rep_name} onChange={this.inputChangeHandler} className="validate" />
                                <label className={formData.rep_name !== '' ? 'active' : ''} htmlFor="rep_name">Rep. Name</label>
                            </div>
                            <div className="input-field col s12">
                                <input id="domain_expiry" name='domain_expiry' defaultValue={formData.domain_expiry} type="text" className="datepicker" />
                                <label className='active' htmlFor="domain_expiry">Domain Expiry(M/D/Y)</label>
                            </div>
                        </div>
                        <div className="ActionDiv">
                            <button className="btn ActionBtn waves-effect waves-light" onClick={this.closeHandler}>Cancel</button>
                            <button type="submit" className="btn ActionBtn waves-effect waves-light">Submit</button>
                        </div>
                    </form>
                </div>
                <div id="delete_modal" className="modal">
                    <div className="modal-content">
                    <h4>Delete Account</h4>
                    <p>Are you sure you want to delete this account?<br/>
                        <b>{this.state.currSelectedRow + ' - ' + this.state.currSelectedName}</b>
                    </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn modal-close waves-effect waves-red">No</button>
                        <button className="btn modal-close waves-effect waves-green" onClick={this.deleteAccount}>Yes</button>
                    </div>
                </div>
            </div>
            {this.state.isLoading && <Spinner />}
            </>
        )
    }
}

export default Dashboard;