import React from 'react'
import { CSVLink } from 'react-csv'

const ExportToCSV = ({csvData, fileName}) => {
    const headers = [
        { label: "ID", key: "account_id" },
        { label: "Name",key: "account_name" },
        { label: "Domain",key: "domain_name" },
        { label: "Server",key: "server_name" },
        { label: "Email",key: "email_type" },
        { label: "Domain Registrar",key: "domain_register" },
        { label: "DR_Username",key: "dr_username" },
        { label: "DR_Password",key: "dr_password" },
        { label: "Name Server",key: "name_server" },
        { label: "Notes",key: "notes" },
        { label: "Rep. Name",key: "rep_name" },
        { label: "Domain Expiry",key: "domain_expiry" },
    ];
    return (      
            <CSVLink data={csvData} filename={fileName} headers={headers} separator={","}>
                <button className='btn waves-effect waves-light tooltipped' data-tooltip='Export To Excel' >
                    <i className='material-icons'>grid_on</i>
                </button>
            </CSVLink>
    )
}

export default ExportToCSV;