import React from 'react'
import './App.css'
import 'materialize-css/dist/css/materialize.min.css';
import ErrorPage from './components/customComponents/ErrorPage'
import ProtectedRoute from './components/customComponents/protectedRoute'
import {Route,Switch,Redirect} from 'react-router-dom';

import Login from './components/login/login'
import Dashboard from './components/dashboard/dashboard'

class App extends React.Component {

  state={
    isAuthenticated:localStorage.getItem('authToken') !== null
  }

  setAuthStatus = (auth) => {
    this.setState({isAuthenticated:auth});
  }

  logoutHandler = () => {
    localStorage.clear();
    this.setState({isAuthenticated:false});
  }

  render(){
    return (
      <div className="App">
        <header className="AppHeader">
          <span>Domains Management</span>
          {this.state.isAuthenticated && <div className="AppUser">
            <div><i style={{marginRight: '2px'}} className="material-icons left">person</i>{localStorage.user}</div>
            <button onClick={this.logoutHandler} className='btn LogoutBtn'><i style={{marginRight: '2px'}} className="material-icons left">logout</i>Logout</button>
          </div>}
        </header>
        <main className="AppMain">
          <Switch>
            <Redirect from='/' exact to='/dashboard' />
            <Route path='/login' exact render={(props) => <Login {...props} authenticate={this.setAuthStatus} />}  />            
            <ProtectedRoute path='/dashboard' exact Component={Dashboard} isAuthenticated={this.state.isAuthenticated} />
            <Route component={ErrorPage} />
          </Switch>
        </main>
        <footer className="AppFooter">
          <p>Powered by <a href='https://breezemaxweb.com/' title="BreezeMaxweb" target='_blank' rel="noopener noreferrer">BreezeMaxWeb<span style={{fontSize:'25px'}}>&reg;</span> Online Media Solutions</a> | <a href='https://breezemaxweb.com/privacy-policy' title="BreezeMaxweb Privacy Policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a></p>
        </footer>
      </div>
    );
  }
}

export default App;
