import React from 'react';

import './spinner.css'

const Spinner =() => {
    return (
    <>
    <div className='Spinner'></div>
    <div className="Backdrop"></div>
    </>
    )
}

export default Spinner;